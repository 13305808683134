import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'
import styled from 'styled-components'

export const Benefits = styled.section`
  background: ${grayscale[100]};

  .icon-circle img {
    max-width: 29px;
    background-color: ${orange[100]};
    border-radius: ${rounded['5']};
  }
  
  .card-image {
    max-width: 309px;
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }

  ul {
    border-bottom: 1px solid ${grayscale[100]};
    overflow: auto hidden;

    @media ${device.tablet} {
      border-bottom: 2px solid ${grayscale[100]};
      justify-content: center;
    }

    @media ${device.desktopXXXL} {
      width: 80%;
      margin: 0 auto !important;
    }
    
    li {
      
      @media ${device.tablet} {
        margin: 0 40px;
      }

      button {
        font-family: "Sora", Helvetica, sans-serif;
        padding: 0 8px;
        
        @media ${device.tablet} {
          padding: 0 8px 5px;
        }
        @media ${device.desktopLG} {
          padding: 0 35px 12px;
        }
        @media ${device.desktopXL} {
          padding: 0 60px 12px;
        }
      }
    }
  }
`
